// Created by http://freehtml5templates.com
// variables for color code: Variable identifiers will be semantic as soon as possible.
$color-code-000000: #000; // #000000 black
$color-code-00008b: #00008b; // #00008b darkblue
$color-code-0000cc: #00c; // #0000cc // used only as div.imagebox border
$color-code-0000ff: #00f; // #0000ff
$color-code-1e469b: #1e469b;
$color-code-333333: #333; // #333333
$color-code-444444: #444; // #444444
$color-code-4e86d9: #4e86d9; // used only as body background
$color-code-555555: #555; // #555555
$color-code-777777: #777; // #777777
$color-code-bedeaf: #bedeaf; // used only as .wrapper background linear-gradient end
$color-code-d7ebce: #d7ebce; // used only as #history-table year, topic in histroy.html
$color-code-eeeeff: #eef; // #eeeeff // used only as div.imagebox background-color
$color-code-f9fcf7: #f9fcf7; // used only as .wrapper background linear-gradient start
$color-code-ffffff: #fff; // #ffffff white
// reset
* {
  margin: 0;
  padding: 0;
}

// render html5 elements as block
header,
footer,
section,
aside,
nav,
article {
  display: block;
}

body {
  background: $color-code-4e86d9;
  color: $color-code-555555;
  font-family: 'Trebuchet MS', Verdana, Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1;
}

// layout
#wrapper {
  background: linear-gradient(to bottom, $color-code-f9fcf7 0%, $color-code-bedeaf 100%);
  border: 2px solid $color-code-333333;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
  width: 960px;
}

// footer
footer {
  clear: both;
  margin: 40px 10px 0;

  h1 {
    border-bottom: 1px $color-code-555555 solid;
    color: $color-code-1e469b;
    font-size: 20px;
    line-height: 1.5em;
    margin: 0 0 20px;
  }

  h2 {
    font-size: 32px;
    font-weight: normal;
    text-shadow: 0 2px 3px $color-code-777777;
  }

  h3 {
    color: $color-code-444444;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.2em;
  }

  small {
    color: $color-code-333333;
    display: block;
    margin: 5px;
    text-align: right;
  }
}

.this-year::after {
  content: '2023';
}

// basics
h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-weight: bold;
}

a {
  color: $color-code-555555;

  &:hover {
    color: $color-code-555555;
  }
}

p {
  margin-bottom: 18px;
}

li {
  padding-left: 5px;
}

hr {
  border: 0;
  border-bottom: 1px solid $color-code-555555;
  border-top: 1px solid $color-code-555555;
  clear: both;
  display: block;
  font-size: 3px;
  line-height: 0;
  margin: 8px auto;
  overflow: visible;
  padding: 0;
  position: relative;
  width: 100%;
}

// nav
nav .menu {
  font-size: 16px;
  font-weight: bold;
  width: 940px;

  ul {
    list-style: none;
    margin: 0;
    text-align: center;

    li::after {
      border-right: 1px $color-code-555555 solid;
      content: '';
      padding: 4.8px 0;
    }

    li:nth-of-type(10)::after {
      border-right: 0;
      margin-right: 1px;
    }

    li:nth-of-type(13) {
      width: 50px;
    }

    li:nth-of-type(15)::after {
      border-right: 0;
    }

    li {
      display: inline-block;
      margin: 0 -5px 0 0;
      padding: 0;
      position: relative;

      a {
        color: $color-code-555555;
        line-height: 38px;
        padding: 9px .3em;
        text-decoration: none;

        &:hover {
          background-image: url('images/limeallowtop.gif');
          background-position: top center;
          background-repeat: no-repeat;
          text-decoration: underline;
        }
      }
    }
  }
}

// header
header {
  margin: 10px 0;

  h1 {
    float: left;
    font-size: 36px;
    margin-left: 10px;
    width: 490px;

    a {
      color: $color-code-1e469b;
      font-weight: bold;
      text-decoration: none;
      text-shadow: 0 2px 3px $color-code-777777;
    }
  }

  h2 {
    clear: right;
    color: $color-code-777777;
    float: right;
    margin-right: 5px;
    padding-top: 8px;
  }
}

// greeting
#greeting {
  margin-bottom: 18px;
  padding-top: 20px;

  h2 {
    color: $color-code-1e469b;
    display: inline;
    font-size: 20px;
  }

  p {
    font-size: 16px;
  }

  img {
    border: 0;
    border-radius: 5px;
    box-shadow: 3px 3px 7px $color-code-777777;
  }
}

// main content
#main {
  color: $color-code-444444;
  font-size: 16px;
  line-height: 1.2em;
  margin: 0 10px;

  h1 {
    color: $color-code-1e469b;
    line-height: 1.5em;
    margin: 0 0 20px;
  }
}

h2,
h3,
h4,
h5,
h6 {
  color: $color-code-1e469b;
  line-height: 1.5em;
  margin: 0 0 20px;
}

#main h3 {
  border-bottom: 1px $color-code-555555 solid;
  font-size: 20px;
  padding-top: 15px;
}

#main h4 {
  margin-top: 30px;
}

.infoboxes {
  margin: 15px 0;

  h5 {
    border-bottom: 1px $color-code-555555 solid;
    font-size: 20px;
  }
}

.group-of-products {
  margin: 0;

  h5 {
    border-bottom: 1px $color-code-555555 solid;
    font-size: 20px;
  }

  &::after {
    clear: both;
  }
}

.infoboxleft {
  float: left;
  width: 300px;
}

.infoboxmid,
.infoboxright {
  float: left;
  margin-left: 20px;
  width: 300px;
}

// test
.product-spec {
  float: left;
  width: 300px;

  &:nth-of-type(n+4) {
    margin-top: 15px;
  }

  &:not(:nth-of-type(3n-2)) {
    margin-left: 20px;
  }

  &:nth-of-type(3n-2) {
    clear: both;
  }
}

.alignleft,
img.alignleft {
  display: inline;
  float: left;
  margin-right: 24px;
  margin-top: 4px;
}

.alignright,
img.alignright {
  display: inline;
  float: right;
  margin-left: 24px;
  margin-top: 4px;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img {
  &.aligncenter {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &.alignleft,
  &.alignright,
  &.aligncenter {
    margin-bottom: 12px;
  }
}

.clearall {
  clear: both;
}

// See http://allabout.co.jp/gm/gc/23923/5/
div.imagebox {
  background-color: $color-code-eeeeff;
  border: 1px dashed $color-code-0000cc;
  float: left;
  margin: 5px;
  width: 130px;

  img {
    margin: 4px 0 0 0;
  }
}

p {
  &.image {
    color: $color-code-00008b;
    font-size: small;
    margin: 5px;
    text-align: center;
  }

  &.exp {
    clear: left;
  }
}

#history-table {
  border: 0;
  padding: 0;

  colgroup {
    &.year {
      background-color: $color-code-d7ebce;
      width: 9em; // 250px; // 128px;
    }

    &.topics {
      background-color: $color-code-d7ebce;
      width: 542px;
    }
  }

  td {
    text-align: left;
    vertical-align: top;
  }
}

.map-iframe-property {
  border-width: 0;
  height: 240px;
  margin: 0;
  width: 240px;
}

section#blog_left {
  float: left;
  text-align: left;
  width: 178px;

  ul {
    margin-left: 2em;
  }
}

section#blog_right {
  float: right;
  text-align: left;
  width: 178px;

  ul {
    margin-left: 2em;
  }
}

.pict {
  height: auto;
  width: 500px;
}

img.facebook_logo {
  vertical-align: -.5em;
}

// for overview
.overview-head-span {
  display: inline-block;
  width: 5em;
}

.overview-business-ul,
.overview-business-ol {
  margin-left: 6em;
}

.overview-ul {
  // list-style-type: square; // none;
  margin-left: 4.5em;

  li {
    display: inline-block;
  }

  li:not(:last-child)::after {
    content: '\03001 '; // IDEOGRAPHIC COMMA html(&#x3001;)<=css(\03001)
  }
}

.overview-partners-list-span {
  display: inline-block;
  margin-top: .3em;
  width: 11em;
}

.overview-partners-list-notice {
  display: block;
  text-align: right;
  font-size: small;
}

.writings {
  margin-bottom: .1em;
  text-indent: 1em;
}

.sign {
  text-align: right;

  span {
    display: inline-block;
    text-align: left;
  }
}

.update-info {
  caption {
    color: $color-code-1e469b;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: .2em;
    text-align: left;
  }

  th {
    color: $color-code-1e469b;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }

  tr th:nth-child(1) {
    width: 6em;
  }
}

.location-table {
  width: 100%;

  &,
  tr,
  td {
    border-style: double;
    border-width: 1px;
  }

  tr {
    td:nth-child(1) {
      text-align: center;
      vertical-align: text-top;
      width: 3em;
    }
  }

  tr:first-of-type {
    > td:nth-child(2) {
      height: 2.4em;
      vertical-align: text-top;
    }
  }
}

.open-large-map {
  color: $color-code-0000ff;
  text-align: left;
}

// flowing thin wheat noodle
.ftwn-grid {
  display: grid;
  grid-gap: 0;
  grid-template-rows: 36px (401px-17px) (780px-36px-401px+17px);
  grid-template-columns: (621px) (940px-621px);
  h2{
    grid-column: 1 / 3;
  }
  img:nth-of-type(1){
    margin: 0 0 0 0;
    width: 621px;
    z-index: 1;
  }
  img:nth-of-type(2){
    margin: 84px 0 0 27px;
    width: 292px;
  }
  img:nth-of-type(3){
    margin: (35px+17px) -324px 0 27px;
    width: 216px;
  }
  img:nth-of-type(4){
    margin: 0 0 0 (940px-(621px+643px));
    width: 643px;
    z-index: 0;
  }
}

.ogr{
  line-height: 1em;
  margin: 0;
  font-weight: normal;
  padding: 0;
  color: $color-code-000000;
  font-size: 16px;
  h5{
    margin: 15px 0 10px;
    font-size: x-large;
  }
  h6{
    margin: 0 0 0 0.5em;
    font-size: large;
  }
  figure{
    figcaption{
      margin: 10px 0 5px 10px;
      font-size: medium;
      color: $color-code-1e469b;
      font-weight: bold;
    }
    img{
      height: 300px;
      margin: 0; // 0 0 0 30px;
    }
    figcaption + img {
      height: calc( 300px - 1em );
    }
  }
  p{
    font-size: 1em;
    margin: 5px 0 10px 50px
  }
  section:first-of-type {
    display: grid;
    grid-gap: 15px 10px;
    grid-template-rows: auto;
    grid-template-columns: 400px 1fr;
    margin: 0;
    padding: 0;
    align-items: end;
  }
  section:nth-of-type(2) {
    display: grid;
    grid-gap: 30px 0;
    grid-template: 300px 1fr / 250px 1fr;
    margin: 0;
    padding: 0;
    align-items: end;
  }
}
